import "../sass/main.scss";
import "./mode_light_dark";
import "./hika_add_to_cart";
import "./navigation";
import "./home";
import "./utils";
import "./modal.js";
import "./hika_cart";
import "./hika_list";
import "./hika_filter";
import "./hika_vote";
import "./configurator";
import "./hika_details";
import "./login";
import "./hika_checkout";
import "./administration";
import "./form";
import "./hika_vendor";
import "./reviews";
import "./jquery.enllax.min.js";
import "./jquery.nice-select.min.js";
// import "./filter_isotope";

import SmoothScroll from "smooth-scroll";

jQuery(document).ready(function ($) {
  $(".close").click(function () {
    $(".alert").hide();
  });
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
  });

  $(".title__overlay").enllax({
    type: "foreground",
    ratio: 0.5,
    direction: "horizontal",
  });

  // $(window).load(function(){
  //   $("input").removeAttr("onchange");
  // })
});
