jQuery(function ($) {

  // --------------------------------------------- OPEN LOGIN
  // $(".btn__connexion").on("click", function () {
  //   $(".modal--login").addClass("modal--active");
  //   $("body").addClass("body__modal--active");
  // });
  // $(".modal__close").on("click", function () {
  //   $(".modal--login").removeClass("modal--active");
  //   $("body").removeClass("body__modal--active");
  // });
  // $(document).on("mouseup", function (e) {
  //   if (!e.target.closest(".modal__wrapper")) {
  //     $(".modal--login").removeClass("modal--active");
  //     $("body").removeClass("body__modal--active");
  //   }
  // });
  // --------------------------------------------- CREER UN COMPTE
});
