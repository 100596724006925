import Rellax from "rellax";
import Swiper from "swiper/bundle";

// ------------------------------------------- SWIPER
jQuery(function ($) {
  // ------------------------------------------- HERO
  const swiper = new Swiper(".swiper__slide", {
    slidesPerView: "auto",
    loop: true,
    speed: 1200,
    autoplay: false,
    allowTouchMove: false,
    // autoplay: {
    //   delay: 3500,
    //   disableOnInteraction: false,
    // },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
  });

  $(".swiper__slide .swiper-slide").hover(
    function () {
      // swiper.autoplay.stop();
    },
    function () {
      // swiper.autoplay.start();
    }
  );

  // ------------------------------------------- REVIEWS
  const swiperReviews = new Swiper(".swiper__reviews", {
    roundLengths: true,
    slidesPerView: "auto",
    loop: true,
    centeredSlides: true,
    speed: 900,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      360: {
        spaceBetween: 10,
      },
      550: {
        spaceBetween: 20,
      },
      640: {
        spaceBetween: 40,
      },
    },
  });

  $(".swiper__reviews .swiper-slide").hover(
    function () {
      swiperReviews.autoplay.stop();
    },
    function () {
      swiperReviews.autoplay.start();
    }
  );
});

window.addEventListener("DOMContentLoaded", function () {
  const home = document.querySelector(".page-101 ");
  if (home) {
    // -------------------------------------------------------------------- RELLAX HERO
    var rellax = new Rellax(".hero__item__img", {
      speed: -6,
    });

    var titleRellax = new Rellax(".title__overlay", {
      speed: 7,
      horizontal: true,
      vertical: false,
    });
    // ------------------------------------------- RELLAX AUTHENTIQUE
    // var rellax2 = new Rellax(".home-content__autenthique .item__img", {
    //   speed: -8,
    //   center: true,
    // });
  }
});
