jQuery(function ($) {
  $(window).resize(function () {
    if ($(window).width() <= 1180) {
      reviews();
    }
  });
  reviews();
  function reviews() {
    if ($(window).width() <= 1180) {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
          $(".reviews--mobile").addClass("reviews--active");
        } else {
          $(".reviews--mobile").removeClass("reviews--active");
        }
      });
    }
  }
});
