jQuery(document).ready(function ($) {
  // ----------------- step 1
  // $(".hikashop_checkout_page_step1 .hika__checkout__wrapper")
  //   .next(".hk-row-fluid")
  //   .andSelf()
  //   .wrapAll("<div class='hika__step__1__wrapper'></div>");

  $(document).on("click", ".btn--show-shipping", function () {
    // $(".btn--show-shipping").on("click", function () {
    $(this)
      .find("i")
      .toggleClass("fa-arrow-alt-circle-up", "fa-arrow-alt-circle-down");
    $(".hikashop_shipping_group").each(function () {
      $(this).slideToggle(200);
    });
  });

  if (window.Oby) {
    $(".hikashop_checkout_page_step2 .buttons__top").hide();
    // ---------------- on laisse affiché uniquement le login avant connexion
    $("#hikashop_checkout_cart_2_3").hide();
    window.Oby.registerAjax(["checkout.user.updated"], function () {
      $(".hikashop_checkout_page_step2 .buttons__top").show();
      $(".checkout__shipping__header").hide();
      $(".hika__checkout__block").addClass("hika__checkout__block--active");
      $("#hikashop_checkout_cart_2_3").show();
      $(".buttons__bottom").show();
    });
  }
});
