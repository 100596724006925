import Swiper from "swiper/bundle";
// var $ = require("jquery");
// var jQueryBridget = require("jquery-bridget");
// var Zoom = require("jquery-zoom");
// jQueryBridget("zoom", Zoom, $);
import zoom from "jquery-zoom";

jQuery(document).ready(function ($) {
  // -------------------------- ZOOM
  if ($(window).width() > 850) {
    let hikaMainImage = document.querySelector(".box__product__zoom");
    if (hikaMainImage) {
      let boxProductZoom = document.querySelector(".box__product__zoom");
      let boxProductZoomCopy = boxProductZoom.cloneNode(true);

      let newBoxProductZoom = document.querySelector(
        ".hikashop_product_main_image"
      );
      newBoxProductZoom.appendChild(boxProductZoomCopy);
    }

    let heightImage = $(".hikashop_product_main_image").height();
    let btnScrollHelp = $(".left__tablet__scroll-help");

    btnScrollHelp.css("height", heightImage + "px");

    window.addEventListener("resize", function () {
      heightImage = $(".hikashop_product_main_image").height();

      btnScrollHelp.css("height", heightImage + "px");
    });

    $("#hikashop_main_image")
      .wrap('<span style="display:inline-block" class="zoom__wrapper"></span>')
      .css("display", "block")
      .parent()
      .zoom({ on: "grab", magnify: 1, onZoomIn: "true" });

    // supprime le bug quand on reste cliqué sur l'image
    // $(".hikashop_slide_next,.hikashop_slide_prev").remove();

    $(".hikashop_small_image_div").on("click", "a", function (e) {
      $(".zoom__wrapper").trigger("zoom.destroy");
      $(".zoom__wrapper").zoom({ on: "grab", magnify: 1 });
    });

    $(".hikashop_small_image_div").on("hover", "a", function (e) {
      $(".zoom__wrapper").trigger("zoom.destroy");
      $(".zoom__wrapper").zoom({ on: "grab", magnify: 1 });
    });
  }
  // ---------- supprime le title et l'action par default sur le lien
  $(".hikashop_img_mode_classic a").removeAttr("title");
  $("#hikashop_main_image").removeAttr("title");
  $(".hikashop_img_mode_classic,.hikashop_small_image_div").on(
    "click",
    "a",
    function (e) {
      e.preventDefault();
    }
  );

  $(".hikashop_slide_next,.hikashop_slide_prev").remove();
  // -------------------------- EO ZOOM
  // ---------------------------------------- RIGHT PART MOBILE ET PC SI LA TAILLE D'ECRAN EST EN DESSOUS DE 780
  if ($(window).width() <= 850) {
    $(".hikashop_product_right_part--big").remove();
  } else {
    $(".hikashop_product_right_part--mobile").remove();
  }
  // ---------------------------------------- EO RIGHT PART MOBILE ET PC

  const swiperDetails = new Swiper(".swiper__details", {
    slidesPerView: "auto",
    // roundLengths: true,
    spaceBetween: 20,
    // freeMode: true,
    loop: true,
    loopAdditionalSlides: 10,
    loopedSlides: 10,
    // centeredSlides: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  $(".swiper__details .swiper-slide").hover(
    function () {
      swiperDetails.autoplay.stop();
    },
    function () {
      swiperDetails.autoplay.start();
    }
  );

  // ------------------------------ style css description

  $(".hikashop_product_description_main").find("span").removeAttr("style");

  // ------------------------------ message qty

  $(document).on("click", ".add-on", function () {
    var $this = $(this);
    var $value = $this.parent().find(".hikashop_product_quantity_field").val();
    var $valueMax = $this
      .parent()
      .find(".hikashop_product_quantity_field")
      .attr("data-hk-qty-max");
    var $stock = $this.parent().find(".hika__check__stock");

    if ($value === 0) {
      return;
    } else if ($value === $valueMax) {
      $stock.addClass("hika__check__stock--active");
    } else {
      $stock.removeClass("hika__check__stock--active");
    }
  });

  // ---------------------------------- vendor
  $(".hika__product__infos")
    .find(".hikamarket_vendor a")
    .click(function (e) {
      e.preventDefault();
    });

  // ---------------------------------- eo vendor

  // ---------------------------------- Right fixed

  // Vérifie si le navigateur prend en charge IntersectionObserver

  if ($(".hikashop_product_page").length > 0) {
    if (!!window.IntersectionObserver) {
      let $hikaInfos = $(".hika__product__right");

      $(window).resize(function () {
        let $hikaPos = $hikaInfos.offset();
        $hikaInfos.css({
          left: $hikaPos.left,
          // top: $hikaPos.top,
        });
      });

      if ($(window).width() > 980) {
        // Position d'origine
        let $hikaPos = $hikaInfos.offset();
        $hikaInfos.css({
          left: $hikaPos.left,
          // top: $hikaPos.top,
        });

        $(window).scroll(function () {
          let $pos = $(window).scrollTop();

          if ($hikaPos.top > $pos) {
            // $hikaInfos.removeClass("hika__product__right--fixed");
            return;
          }
        });

        let observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!entry.isIntersecting) {
                // entry.target.classList.add("hika__product__right--fixed");
              }
            });
          },
          {
            threshold: 1,
          }
        );

        let el = document.querySelector(".hika__product__right");
        observer.observe(el);
      }
    }
  }
});
