jQuery(document).ready(function($){


  // --------------------------------------------- PASSWORD
  $(document).on("click", ".fa-eye", function (e) {
    var $pass = $(e.target).parent().find("input");
    $(e.target).toggleClass("fa-eye-slash");
    if ($pass.attr("type") === "password") {
      $pass.attr("type", "text");
    } else {
      $pass.attr("type", "password");
    }
  });


$('#jform_password1,#jform_password2').parent().addClass('form__password').append('<i class="fas fa-eye"></i>');

})