import Rellax from "rellax";

jQuery(document).ready(function ($) {
  if ($("body").hasClass("page-175")) {
    // -------------------------------------------------------------------- CATEGORIES

    const $foie = $(".foie-gras").length;
    const $confit = $(".confit-de-canard").length;
    const $charcuterie = $(".charcuterie").length;
    const $plats = $(".plats-cuisines").length;
    const $coffrets = $(".coffret-cadeaux").length;
    const $autres = $(".autres-specialites").length;

    const $allProducts =
      $foie + $confit + $charcuterie + $plats + $coffrets + $autres;

    $(".li__foie").find(".filter__value").html($foie);
    $(".li__confit").find(".filter__value").html($confit);
    $(".li__charcuterie").find(".filter__value").html($charcuterie);
    $(".li__plats").find(".filter__value").html($plats);
    $(".li__coffrets").find(".filter__value").html($coffrets);
    $(".li__autres").find(".filter__value").html($autres);
    $(".li__all-products").find(".filter__value").html($allProducts);

    $(window).load(function () {
      $("#hikashop_module_185").isotope({
        itemSelector: ".card",
      });

      $(".filter-button-group").on("click", "li", function () {
        var filterValue = $(this).attr("data-filter");
        $("#hikashop_module_185").isotope({ filter: filterValue });
        $(".filter-button-group li").removeClass("active");
        $(this).addClass("active");
      });
      // ------------------------------------------
      $(".producteurs__nav .title").click(function () {
        $(".producteurs__nav ul").slideToggle("fast");
        $(this)
          .find("i")
          .toggleClass("fa-arrow-alt-circle-up  fa-arrow-alt-circle-down");
      });
      if ($(window).width() < 980) {
        $(".producteurs__nav li").click(function () {
          $(".producteurs__nav ul").slideToggle("fast");
          $(".producteurs__nav .title")
            .find("i")
            .toggleClass("fa-arrow-alt-circle-up  fa-arrow-alt-circle-down");
        });
      }
    });

    // ------------------------ si la catégorie est vide alors on ne l'affiche pas
    $(".producteurs__nav")
      .find("li")
      .each(function () {
        if ($(this).find(".filter__value").text() == 0) {
          $(this).hide();
        }
      });

    // -------------------------------------------------------------------- PARALLAX
    // var rellax = new Rellax(".vendor__img__main img", {
    //   speed: -6,
    // });
  }
});
