jQuery(function ($) {
  //==================================================================================== PAGE PRODUIT

  // supprimer les input checked au chargement de la page pour : type de bijoux, genre, matière

  $(
    "#hikashop_product_typedebijoux,#hikashop_product_genre,#hikashop_product_matiere"
  )
    .find("input")
    .prop("checked", false);

  //==================================================================================== EO PAGE PRODUIT

  //==================================================================================== PAGE SHIPPING / PAIEMENT

  //------------------------------------------------------ Titre page

  if (window.location.href.indexOf("plugin/plugin_type-shipping") > -1) {
    $(".admin__title").html("Méthodes de livraison");
  }
  if (window.location.href.indexOf("plugin/plugin_type-payment") > -1) {
    $(".admin__title").html("Méthode de paiement");
  }

  //------------------------------------------------------ Lien de menu plugin Livraison
  if (window.location.href.indexOf("plugin/plugin_type-shipping") != -1) {
    $(".item-243").removeClass("active");
    $(".item-266").addClass("active");
  }

  //------------------------------------------------------ Lien de menu plugin Paiement
  if (window.location.href.indexOf("plugin/plugin_type-payment") != -1) {
    $(".item-243").removeClass("active");
    $(".item-329").addClass("active");
  }

  //==================================================================================== EO PAGE SHIPPING / PAIEMENT

  //------------------------------------------------------ Détails livraison choix zone

  $("#hikamarket_shipping_restrictions")
    .find("a")
    .click(function () {
      $(this).toggleClass("active");
    });

  //------------------------------------------------------ Bouton menu click
  $("#admin__menu__btn").on("click", function () {
    $(".admin__menu").toggleClass("admin__menu--active");
    $(this).toggleClass("menu__btn--active");
    // $("body").toggleClass("body--active");
  });

  $(window).scroll(function () {
    $(".admin__menu").removeClass("admin__menu--active");
    $("#admin__menu__btn").removeClass("menu__btn--active");
  });

  // ------------------------------ édition / nouveau produit
  // --------------------- fixed prix ht
  var $priceTotal = parseFloat($("#hikamarket_price_0_price").val());
  $("#hikamarket_price_0_price").val($priceTotal.toFixed(2));

  // --------------------- fixed prix ht

  $('#dataproductproduct_tax_id option[value=""]').hide();

  $("#dataproductproduct_tax_id").on("change", function () {
    $(".hika_product_price_ttc").show();
    // $(".hikamarket_product_price label span").html("HT");
    $(".hikamarket_product_price label span").html("TTC");

    var $price = $("#hikamarket_price_0_price").val();
    var $result = $("#hikamarket_price_0_with_tax_span");
    var $val = $(this).val();

    switch ($val) {
      case "29":
        var $priceTtc = 1.1 * $price;
        $result.html($priceTtc.toFixed(2));
        break;
      case "30":
        var $priceTtc = 1.2 * $price;
        $result.html($priceTtc.toFixed(2));
        break;
      case "31":
        var $priceTtc = 1.055 * $price;
        $result.html($priceTtc.toFixed(2));
        break;
      case "32":
        var $priceTtc = 1 * $price;
        $result.html($priceTtc.toFixed(2));
        $(".hikamarket_product_price label span").html("net");
        $(".hika_product_price_ttc").hide();
        break;
    }
  });
  $("#hikamarket_price_0_with_tax_span").on("change", function () {
    $(this).toFixed(2);
  });

  // --------------------- category

  $("#data_product_categories_d1")
    .find(".node")
    .on("click", function () {
      // $('.namebox.ui-sortable-handle').remove();
      // console.log('ok');
    });

  // --------------------- eo category
});
