jQuery(document).ready(function ($) {
  $(document).on("click", ".notifyjs-container", function () {
    $(".notifyjs-wrapper").hide();
  });

  if (window.Oby) {
    window.Oby.registerAjax(["cart.updated"], function (params) {
      let $this = $(".hikashop_product_page"),
        $productPrice = $this.find(".hikashop_product_price").text(),
        $productName = $this.find(".hikashop_product_name_main").text(),
        $productImg = $this.find("#hikashop_main_image").attr("src");
      if (params.id != 0) {
        $(".modal").addClass("modal--active");
        $(".modal__total-qty,.modal__total-price").text("").addClass("active");
        $(".modal__name__value").text($productName);
        $(".modal__price__value").text($productPrice);
        $(".modal__img__value").attr("src", $productImg);
        $(".notifyjs-wrapper").hide();
      }

      var data = {};
      data["action"] = "cart_updated";
      data["cart_id"] = params.id; // ici l'id du cart
      data["product_id"] = params.product_id; // ici l'id du product added

      $.ajax({
        data: data,
        url: "index.php?option=com_gixmassaction&view=ajax&layout=cart_updated&tmpl=component",
        type: "POST",
        format: "raw",
        success: function (result) {
          // console.log(result);
          var tab = JSON.parse(result);

          // tab['cart_id']
          // tab['product_id']
          // tab['product_name']
          // tab['price_value']
          // tab['file_path']
          // tab['cart_total_quantity']
          // tab['cart_total_price']

          // console.log(params.id);

          if (tab["cart_total_quantity"] <= 1) {
            $(".modal__total-qty").text(tab["total_quantity"] + " bijou");
          } else {
            $(".modal__total-qty").text(tab["total_quantity"] + " bijoux");
          }
          $(".modal__total-price").text(tab["total_price"].toFixed(2) + " €");

          $(".modal__total-qty,.modal__total-price ").removeClass("active");
          // $(".modal__action").removeClass("modal--loading");

          // $(".hikashop_product_page").attr(
          //   "data-stock",
          //   tab["this_product_quantity"]
          // );

          // if (tab["this_product_quantity"] == "0") {
          //   $(
          //     '.btn--add--cart[data-addtocart="' + params.product_id + '"]'
          //   ).addClass("heell");
          // }

          // console.log(tab["this_product_quantity"]);

          // si la quantité = 0 alors on remplace le bouton d'ajout au panier par un message
        },
        error: function (result) {
          console.log("fail");
          console.log(result);
        },
      }).done(function () {});
    });
  }

  // --------------------------------------------- ADD TO CART

  //   let $stockItem = $(".hikashop_product_page").data("stock");

  //   if ($stockItem) {
  //     if ($stockItem == 0) {
  //       $(".btn--add-cart")
  //         .parent()
  //         .append(
  //           '<span class="qty_max_in_cart">Quantité maximum atteinte</span>'
  //         );
  //       $(".btn--add-cart").hide();
  //     }
  //   }

  //   function addToCart() {
  //     $(".modal__total-qty,.modal__total-price").text("").addClass("active");
  //     let $btnAddToCart = $(".btn--add-cart"),
  //       $this = $(".hikashop_product_page"),
  //       $dataStock = $this.attr("data-stock"),
  //       $productPrice = $this.find(".hikashop_product_price").text(),
  //       $productName = $this.find(".hikashop_product_name_main").text(),
  //       $productImg = $this.find("#hikashop_main_image").attr("src");

  //     $(".modal__name__value").text($productName);
  //     $(".modal__price__value").text($productPrice);
  //     $(".modal__img__value").attr("src", $productImg);

  //     if ($dataStock == 1 || $dataStock == 0) {
  //       $btnAddToCart
  //         .parent()
  //         .append(
  //           '<span class="qty_max_in_cart">Quantité maximum atteinte</span>'
  //         );
  //       $btnAddToCart.hide();
  //     }
  //     if ($dataStock > 1 || $dataStock == -1) {
  //       $(".modal").addClass("modal--active");
  //       $("body").addClass("body__modal--active");
  //     }
  //   }

  //   // ========================================================== EMPECHER L'UTILISATEUR DE VALIDER LE PANIER SI UNE TAILLE N'EST PAS CHOISIE
  //   let $tailleBijou = $(".hikashop_product_custom_item_info_table");

  //   if ($tailleBijou) {
  //     if ($tailleBijou.find(".hkradio-inline input").length > 1) {
  //       $tailleBijou.find("input").on("change", function () {
  //         $(".btn--add-cart").removeClass("cart-invalid");
  //         $tailleBijou.addClass("cart--valid");
  //       });
  //     } else {
  //       $tailleBijou.addClass("cart--valid");
  //     }

  //     // if ($tailleBijou.find(".hkradio-inline input").length <= 1) {
  //     //   $tailleBijou.addClass("cart--valid");
  //     // }
  //   }

  //   $(document).on("click", ".btn--add-cart", function () {
  //     if ($tailleBijou.length > 0) {
  //       $(".btn--add-cart").addClass("cart-invalid");
  //       if ($tailleBijou.hasClass("cart--valid")) {
  //         $(".btn--add-cart").removeClass("cart-invalid");
  //         addToCart();
  //       }
  //     } else {
  //       addToCart();
  //     }
  //   });
});
