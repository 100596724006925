const modeWrapper = document.querySelector(".mode__light-dark");
let widthSearch = document.querySelector(".header__search");

if (widthSearch) {
  widthSearch = widthSearch.offsetWidth;

  if (window.innerWidth > 1180) {
    modeWrapper.style.setProperty("left", widthSearch + 28 + "px");
  }

  window.addEventListener("resize", function () {
    let widthSearch = document.querySelector(".header__search").offsetWidth;
    if (window.innerWidth > 1180) {
      modeWrapper.style.setProperty("left", widthSearch + 28 + "px");
    } else {
      modeWrapper.style.setProperty("left", 200 + "px");
    }
  });
}

const toggleSwitch = document.querySelector(
  '.theme-switch input[type="checkbox"]'
);
const btnMode = document.querySelector(".theme-switch");
const currentTheme = localStorage.getItem("theme");
const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

if (btnMode) {
  if (currentTheme) {
    document.documentElement.setAttribute("data-theme", currentTheme);

    if (currentTheme === "dark") {
      toggleSwitch.checked = true;
      document.body.classList.toggle("dark-theme");
      btnMode.classList.add("dark");
    } else {
      btnMode.classList.add("light");
      // document.body.classList.toggle("light-theme");
    }
  }
}
function switchTheme(e) {
  if (e.target.checked) {
    document.documentElement.setAttribute("data-theme", "dark");
    btnMode.classList.remove("light");
    btnMode.classList.add("dark");
    localStorage.setItem("theme", "dark");
  } else {
    document.documentElement.setAttribute("data-theme", "light");
    btnMode.classList.remove("dark");
    btnMode.classList.add("light");
    localStorage.setItem("theme", "light");
  }
}

function detectColorScheme() {
  var theme = "light"; //default to light

  //local storage is used to override OS theme settings
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") == "dark") {
      var theme = "dark";
    }
  } else if (!window.matchMedia) {
    //matchMedia method not supported
    return false;
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //OS theme setting detected as dark
    var theme = "dark";
  }

  //dark theme preferred, set document with a `data-theme` attribute
  if (theme == "dark") {
    document.documentElement.setAttribute("data-theme", "dark");
  }
}
detectColorScheme();
if (btnMode) {
  toggleSwitch.addEventListener("change", switchTheme, false);
}
