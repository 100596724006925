jQuery(function ($) {
  //------------------------------------------------------ MENU WIDTH PC SCROLL

  // ---------------------------------- resize
  // $(window).resize(function () {
  //   if ($(window).width() <= 1180) {
  //     fixedHeader();
  //   }
  // });

  // headerWidth();

  // function headerWidth() {
  //   $(window).on("scroll", function () {
  //     if ($(this).scrollTop() > 400) {
  //       $("body").addClass("body__scroll--active");
  //     } else {
  //       $("body").removeClass("body__scroll--active");
  //     }
  //   });
  // }

  //------------------------------------------------------ MENU FIXED TABLETTE / MOBILE

  fixedHeader();

  // ---------------------------------- resize
  $(window).resize(function () {
    if ($(window).width() <= 1180) {
      fixedHeader();
    }
  });

  function fixedHeader() {
    let $header = $(".header");
    let $itemChange = $(
      ".header,.header__top__services,.admin__user,.mode__light-dark"
    );
    let $finalPosition;
    let $heightMargin = 50;
    let $heightStart = $header.height() + $heightMargin;

    $(window).resize(function () {
      $(".header__menu").removeClass("header__menu--active");
      $("#menu__btn").removeClass("menu__btn--active");
      $("body").removeClass("body--active");
    });

    if ($(window).width() <= 1180) {
      $(window).scroll(function () {
        let $scrollPosition = $(window).scrollTop();
        let $currentPosition = $scrollPosition;

        if (
          $finalPosition < $currentPosition &&
          $currentPosition > $heightStart
        ) {
          $itemChange.addClass("header--hidden");
          $(".header__menu").removeClass("header__menu--active");
          $("#menu__btn").removeClass("menu__btn--active");
          $("body").removeClass("body--active");
        } else {
          $itemChange.removeClass("header--hidden");
        }

        $finalPosition = $currentPosition;
      });
    }
  }

  //------------------------------------------------------ Bouton menu click
  $(document).on("click", "#menu__btn", function () {
    $(".header__menu").toggleClass("header__menu--active");
    $(this).toggleClass("menu__btn--active");
    $("body").toggleClass("body--active");
  });
  // if ($(window).width() <= 768) {
  //   $("#menu__btn").on("click", function () {
  //     $("body").toggleClass("body--active");
  //     $(".header__menu").toggleClass("header__menu--active");
  //   });
  // }

  $(document).on("mouseup", function (e) {
    if (
      !e.target.closest(
        ".header__menu,.filter__wrapper,.product__configurator "
      )
    ) {
      $(".header__menu").removeClass("header__menu--active");
      $("#menu__btn").removeClass("menu__btn--active");
      $("body").removeClass("body--active");
    }
  });
  // ------------------------------------------- nav child
  $(".parent").click(function () {
    $(".nav-child").removeClass("nav-child--active");
    $(this).find(".nav-child").toggleClass("nav-child--active");
  });

  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".parent")) {
      $(".nav-child").removeClass("nav-child--active");
    }
  });
});
