jQuery(document).ready(function ($) {
  // =========================================================== SESSION STORAGE

  let countProduct = sessionStorage.getItem("count_product");
  let $configuratorExist = $(".product__configurator ").length;

  if ($configuratorExist) {
    if (countProduct) {
      document.querySelector(".configurator__result .result__value").innerHTML =
        countProduct;
    }
  }

  //on page load, check the appropriate radio.
  var onloadConfigurator = JSON.parse(
    sessionStorage.getItem("configuratorValue")
  );
  $.each(onloadConfigurator, function (key, value) {
    $("#" + key).prop("checked", value);
  });

  // ================ Saving checks

  // radioes collection.
  var allConfigValues = $(".configurator__item input[type='radio']");

  // On change handler.
  allConfigValues.on("change", function () {
    // Check how many radioes we have.
    var jsonConfigValues = {};
    // console.log("There is " + allConfigValues.length + " radios.");

    // Building the json.
    for (i = 0; i < allConfigValues.length; i++) {
      // console.log(allConfigValues.eq(i).attr("id"));
      // console.log(allConfigValues.eq(i).is(":checked"));
      jsonConfigValues[allConfigValues.eq(i).attr("id")] = allConfigValues
        .eq(i)
        .is(":checked");
    }
    // console.log("jsonradios: " + JSON.stringify(jsonConfigValues));

    // Setting localStorage.
    sessionStorage.setItem(
      "configuratorValue",
      JSON.stringify(jsonConfigValues)
    );
    // console.log("LocalStorage: " + sessionStorage.getItem("configuratorValue"));
  });

  // =========================================================== EO SESSION STORAGE

  let $linkMenu = $(".menu__item, .item").find(".menu__item--link"),
    $itemTypeInput = $(".items--type").find("input"),
    $configuratorWrapper = $(".product__configurator"),
    $closeBtn = $(".configurator__close"),
    $itemInput = $configuratorWrapper.find(".item input"),
    $configuratorBtn = $(".btn--configurator"),
    $configuratorResult = $(".configurator__result"),
    $configuratorFooter = $(".configurator__footer"),
    $itemType = $(".item--type"),
    $itemGenre = $(".item--genre"),
    $itemMatiere = $(".item--matiere"),
    $openConfigurator = $(".configurator__open, .configurator__start"),
    $itemsType = $(".items--type"),
    $itemsGenre = $(".items--genre"),
    $itemsMatiere = $(".items--matiere");

  $linkMenu.load(function (e) {
    e.preventDefault();
  });

  $itemMatiere.hide();
  $configuratorFooter.hide();

  //----------- Désactive le lien, fait sortir le configurator
  $linkMenu.on("click", function (e) {
    var $thisValue = $(this).text();
    e.preventDefault();
    $linkMenu.parent().removeClass("active");
    $(this).parent().addClass("active");
    $configuratorWrapper.addClass("active");
    $itemGenre.addClass("active");
    $itemsType.hide();
    $itemType.find(".title__choice").text($thisValue);
    $itemType.find(".btn__modify__configurator").show();
  });

  $itemType.find("input").on("click", function () {
    var $thisValue = $(this).parent().find(".item__name").text();
    $itemType.find(".title__choice").text($thisValue);
    $itemType.find(".btn__modify__configurator").show();
    $itemsType.hide();
  });

  $itemGenre.find("input").on("click", function () {
    var $thisValue = $(this).parent().find(".item__name").text();
    $itemGenre.find(".title__choice").text($thisValue);
    $itemGenre.find(".btn__modify__configurator").show();
    $itemsGenre.hide();
    $itemMatiere.show();
  });

  $itemMatiere.find("input").on("click", function () {
    var $thisValue = $(this).parent().find(".item__name").text();
    $itemMatiere.find(".title__choice").text($thisValue);
    $itemMatiere.find(".btn__modify__configurator").show();
    $itemsMatiere.hide();
    $configuratorFooter.show();
  });

  $(".btn__modify__configurator").on("click", function () {
    $(this).closest(".configurator__item").find(".items").show();
  });

  $(".hero__modify").on("click", function () {
    $configuratorWrapper.addClass("active");
  });

  $openConfigurator.on("click", function () {
    $configuratorWrapper.toggleClass("active");
  });

  //----------- Bouton fermer
  $closeBtn.on("click", function () {
    $configuratorWrapper.removeClass("active");
    $(".header__menu").removeClass("header__menu--active");
    $("#menu__btn").removeClass("menu__btn--active");
    $("body").removeClass("body--active");
  });

  //----------- si clique ailleurs sur le site
  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".product__configurator,.theme-switch,.menu__main")) {
      $configuratorWrapper.removeClass("active");
    }
  });

  //----------- Lors du clic sur le lien de menu, si son data-id correspond à l'id du type de bijoux alors on checked
  $linkMenu.on("click", function () {
    let $linkId = $(this).data("id");

    $itemTypeInput.each(function () {
      let $inputId = $(this).attr("id");

      if ($linkId === $inputId) {
        $(this).prop("checked", true);
      }
    });
  });

  //----------- Vérifier si dans les 3 élements au moins un est checked

  function checkInput() {
    let $inputType = $("input[name='type']:checked").length,
      $inputTypeText = $("input[name='type']:checked")
        .parent()
        .find(".item__name")
        .text(),
      $inputGenre = $("input[name='genre']:checked").length,
      $inputGenreText = $("input[name='genre']:checked")
        .parent()
        .find(".item__name")
        .text(),
      $inputMatiere = $("input[name='matiere']:checked").length,
      $inputMatiereText = $("input[name='matiere']:checked")
        .parent()
        .find(".item__name")
        .text();

    $itemType.find(".title__choice").text($inputTypeText);
    $itemGenre.find(".title__choice").text($inputGenreText);
    $itemMatiere.find(".title__choice").text($inputMatiereText);

    if ($inputType > 0) {
      $itemGenre.addClass("active");
    }
    if ($inputType && $inputGenre > 0) {
      $itemMatiere.addClass("active");
      $itemsGenre.hide();
      $itemMatiere.show();
      $itemGenre.find(".btn__modify__configurator").show();
    }

    if ($inputType && $inputGenre && $inputMatiere > 0) {
      $configuratorBtn.addClass("active");
      $configuratorResult.addClass("active");
      $itemsMatiere.hide();
      $itemMatiere.find(".btn__modify__configurator").show();
      $configuratorFooter.show();
    }
  }

  $(window).on("load", function () {
    checkInput();
  });

  $itemInput.on("change", function () {
    checkInput();
  });
});
