jQuery(document).ready(function ($) {
  //------------------------------------ Réglage hauteur viewport sur mobile
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  /* CSS
    height: 100vh;  //Fallback for browsers that do not support Custom Properties 
    height: calc(var(--vh, 1vh) * 100);
  */

  //------------------------------------ HAUTEUR PANIER MOBILE / DESKTOP

  let heightCartTitle = $(".cart__content__title").outerHeight();
  let heightCartBottom = $(".cart__checkout__mobile").outerHeight();
  let totalHeightCart = heightCartTitle + heightCartBottom + "px";

  if ($(window).width() < 550) {
    $(".cart__content").css({
      height: "calc((var(--vh, 1vh) * 100) - " + totalHeightCart + ")",
    });
  }
  // } else {
  //   $(".cart__content").css({
  //     height: "calc(100% - 230px)",
  //   });
  // }

  $(window).resize(function () {
    if ($(window).width() < 550) {
      $(".cart__content").css({
        height: "calc((var(--vh, 1vh) * 100) - " + totalHeightCart + ")",
      });
    } else if ($(window).width() > 1550) {
      $(".cart__content").css({
        height: "calc(100% - 230px)",
      });
    } else {
      $(".cart__content").css({
        height: "calc(100% - 170px)",
      });
    }
  });

  //------------------------------------ BOUTON PANIER FIXED
  var $cart = $(".hikashop_cart");
  var $heightHeader = $(".header__top").outerHeight();

  $(window).scroll(function () {
    var $pos = $(window).scrollTop();
    if ($pos > $heightHeader) {
      $cart.addClass("btn__cart--fixed");
    } else {
      $cart.removeClass("btn__cart--fixed");
    }
    if ($pos > $heightHeader && $pos < 150) {
      $cart.removeClass("btn__cart--visible");
    }
    if ($pos > 150) {
      $cart.addClass("btn__cart--visible");
    }
  });

  //------------------------------------ vérification du stock

  $(".hikashop_product").each(function () {
    var $this = $(this);

    var $productQtyOrigin = parseInt($this.find(".hika__stock span").text());
    var $productQty = $productQtyOrigin;

    if ($productQty > 4) {
      // $this.find(".hika__stock").addClass("hika__stock--fine");
    }

    $this.find(".btn--add-cart").click(function () {
      if ($productQty == 1) {
        // $this.find(".hika__stock").hide();
        // $this
        //   .find(".hikashop_listing_img_title")
        //   .append('<span class="hika__stock--empty">Trop de succès !</span>');
        // $this.find(".btn--add-cart").hide();
        // return;
      }

      if ($productQty > 0) {
        $productQty -= 1;
        // $this.find(".hika__stock span").html($productQty);
      }

      // -------------- background stock

      if ($productQty > 4) {
        // $this.find(".hika__stock").addClass("hika__stock--fine");
      } else {
        // $this.find(".hika__stock").removeClass("hika__stock--fine");
      }
    });
  });

  //------------------------------------ clic sur "ajouter au panier"

  $(document).on("click", ".btn--add-cart", function () {
    var $qtyCart = $(".qty__title").text();
    var $cartActive = $(document).find(".hikashop_cart");

    //  fait apparaitre le panier lors du clic sur ajout au panier
    if ($(window).width() > 1180) {
      // $cartActive.removeClass("cart--active");
      // $cartActive.addClass("cart--active");
    }

    // si la quantité du panier est 0 et le panier à la class active on enleve la class active pour ne pas afficher le panier
    if ($qtyCart === "-" && $cartActive.hasClass("cart--active")) {
      $cartActive.removeClass("cart--active");
    }
    // si window < 1180 on fait descendre le header
    if ($(window).width() < 1180) {
      $(".header,.header__top__services").removeClass("header--hidden");
    }
    // ------------------------ pop up

    // let addCartModal = document.createElement("div");
    // addCartModal.classList.add("add__cart__modal__item");
    // addCartModal.innerHTML = `
    // <svg height="50px" width="50px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    //     <path d="m369.164062 174.769531c7.8125 7.8125 7.8125 20.476563 0 28.285157l-134.171874 134.175781c-7.8125 7.808593-20.472657 7.808593-28.285157 0l-63.871093-63.875c-7.8125-7.808594-7.8125-20.472657 0-28.28125 7.808593-7.8125 20.472656-7.8125 28.28125 0l49.730468 49.730469 120.03125-120.035157c7.8125-7.808593 20.476563-7.808593 28.285156 0zm142.835938 81.230469c0 141.503906-114.515625 256-256 256-141.503906 0-256-114.515625-256-256 0-141.503906 114.515625-256 256-256 141.503906 0 256 114.515625 256 256zm-40 0c0-119.394531-96.621094-216-216-216-119.394531 0-216 96.621094-216 216 0 119.394531 96.621094 216 216 216 119.394531 0 216-96.621094 216-216zm0 0" />
    // </svg>
    // Produit ajouté au panier
    // `;
    // document
    //   .querySelector(".add__cart__modal__container")
    //   .appendChild(addCartModal);
    // requestAnimationFrame(() => {
    //   addCartModal.classList.add("add__cart__modal__item--active");
    //   setTimeout(() => {
    //     addCartModal.classList.remove("add__cart__modal__item--active");
    //   }, 1700);
    //   $(".add__cart__modal__item").delay(2000).fadeOut();
    // });
  });
  //------------------------------------ PANIER VIDE (-)
  $(document).on("click", ".btn__cart--empty", function () {
    $(".cart__empty").addClass("cart__empty--active");
  });
  $(document).on("click", ".cart__empty__close", function () {
    $(".cart__empty").removeClass("cart__empty--active");
    $("body").removeClass("body--cart--active");
  });
  $(document).on("mouseup", function (e) {
    if (!e.target.closest(".cart__empty")) {
      $(".cart__empty").removeClass("cart__empty--active");
      $("body").removeClass("body--cart--active");
    }
  });
  //------------------------------------ Ouvrir le panier

  $(document).on("click", ".btn__cart--full ", function () {
    $(".hikashop_cart").addClass("cart--active");
    $("body").addClass("body--cart--active");
  });

  // if ($(window).width() < 1180) {
  //   console.log("salut");
  //   $(window).scroll(function () {
  //     $(".hikashop_cart").removeClass("cart--active");
  //   });
  // }

  // ------------------------------- Pour fermer le panier

  $(document).on("click", ".cart__close,.btn--back--checkout", function () {
    $("body").removeClass("body--cart--active");
    $(".hikashop_cart").removeClass("cart--active");
  });

  $(document).on("mouseup", function (e) {
    if (
      !e.target.closest(".hikashop_cart") &&
      !e.target.closest(".hikashop_product")
    ) {
      $(".hikashop_cart").removeClass("cart--active");
      $("body").removeClass("body--cart--active");
    }
  });
});
