jQuery(document).ready(function ($) {
  $(document).on("click", ".hikashop_filter_title", function () {
    $(this).toggleClass("active--down");
    $(this).parent().find(".filter_values_container").slideToggle(100);
  });

  // $(window).load(function () {
  //   $(
  //     ".hikashop_filter_Formedelaperle_37 .hikashop_filter_title,.hikashop_filter_Couleurdelaperle_36 .hikashop_filter_title"
  //   )
  //     .toggleClass("active--down")
  //     .parent()
  //     .find(".filter_values_container")
  //     .slideToggle(100);
  // });

  // $(
  //   "#filter_values_container_Trier_33_hikashop_category_information_menu_593 .hkform-control"
  // ).niceSelect();

  $(document).on("click", ".close__filter,.btn", function () {
    $(".filter__wrapper").removeClass("filter__wrapper--active");
    $(".btn__filter__mobile").show();
    $(".btn__filter--mobile").removeClass("btn__mobile--filter--active");
    $("body").removeClass("body--active");
    // $(".filter__reset").hide();
  });

  $(document).on("click", ".filter__reset", function () {
    $(".filter__wrapper").removeClass("filter__wrapper--active");
    $(".btn__filter__mobile").show();
    $(".btn__filter--mobile").removeClass("btn__mobile--filter--active");
    $("body").removeClass("body--active");
    // $(".filter__reset").hide();
  });

  if ($(window).width() <= 1180) {
    $(document).on(
      "click",
      ".hikashop_filter_main input, .hikashop_filter_main select",
      function (e) {
        $(this).removeAttr("onchange");
      }
    );
  }

  // // =============================================================== A REGLER
  $(document).on("click", ".btn__filter__mobile", function () {
    $(".filter__wrapper").addClass("filter__wrapper--active");
    $(".btn__filter__mobile").hide();
    $(".btn__filter--mobile").addClass("btn__mobile--filter--active");
    $("body").addClass("body--active");
  });

  $(document).on("click", ".btn__filter--mobile--active", function () {
    $(window).scrollTop(0);
  });

  // =============================================================== EO
});
