import Rellax from "rellax";

jQuery(document).ready(function ($) {
  // ============================================================================================== HERO PARALLAX
  let element = document.querySelector(".list__hero__img");

  if (document.body.contains(element))
    window.addEventListener("load", function () {
      // ----------------------------- HERO
      var rellax = new Rellax(element, {
        speed: -4,
      });
    });
  // ============================================================================================== EO HERO PARALLAX
});
